import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
// import { DefaultStyles } from "@walltowall/p3r-races"
import DefaultStyles from "./components/DefaultStyles"

ReactDOM.render(
  <React.Fragment>
    <DefaultStyles />
    <App />
  </React.Fragment>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
