import React, { useEffect, useState } from "react"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom"
import {
  Events,
  Event,
  Homepage,
  Page,
  // Races,
  Race,
  News,
  Post,
  Search,
  SiteProvider,
  SubHeader,
  DefaultFooter,
  DefaultHeader,
} from "@walltowall/p3r-races"
import {
  primaryYellow,
  primaryBlack,
  secondaryRed,
  button,
  newsEvents,
} from "./colors"
// import MarathonHeader from './components/MarathonHeader'
// import MarathonFooter from './components/MarathonFooter'
import Error404 from "./components/Error404"
import moveM from "./images/move_m.svg"
import "swiper/css/swiper.css"
import "@fullcalendar/core/main.css"
import "@fullcalendar/daygrid/main.css"
import newsEventsBackgroundFallback from "./images/EQT10Miler_Blog_Default.png"
import queryString from "query-string"

function App() {
  const [menus, setMenus] = useState(null)
  const root =
    process.env.NODE_ENV === "development"
      ? "http://localhost:4242"
      : "https://p3r.org"

  useEffect(() => {
    const menuParams = queryString.stringify({
      main: "10 Miler Main Menu",
      sub: "10 Miler Sub Menu",
      footer: "10 Miler Footer",
      footer_secondary: "10 Miler Footer Secondary",
    })

    fetch(`${root}/sites/site_menus.json?${menuParams}`)
      .then((response) => response.json())
      .then((data) => {
        setMenus(data.menus)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router>
      <SiteProvider root={root}>
        {menus && (
          <>
            <SubHeader menus={menus} />

            {/* TODO: create a default header in p3r-races */}
            <DefaultHeader
              site={"eqt-pittsburgh-10-miler"}
              logoAlt='EQT Pittsburgh 10 Miler'
              mediumLogoProperties={{
                mWidth: "116px",
                mHeight: "108px",
                mLeft: "6px",
              }}
              largeLogoProperties={{
                lWidth: "140px",
              }}
              menus={menus}
              menuHover='rgba(0,0,0,0.7)'
              background={primaryYellow}
              registerBackground={secondaryRed}
              navHoverBorderBottom={secondaryRed}
              searchBackground={primaryBlack}
              primaryText='white'
            />
          </>
        )}

        <Switch>
          <Route exact path={["/", "/pages/home-eqt-pittsburgh-10-miler"]}>
            <Homepage
              site='eqt-pittsburgh-10-miler'
              newsEventsBackgroundFallback={newsEventsBackgroundFallback}
              colors={{
                primary: primaryBlack,
                secondary: primaryYellow,
                tertiary: secondaryRed,

                countdownBackground: primaryYellow,

                menuBackground: primaryBlack,

                primaryText: "white",

                cardsBackground: primaryYellow,
                cardBackground: "#f8981e",
                cardTitle: secondaryRed,

                link: "black",
                linkUnderline: primaryYellow,

                racesBackground: primaryBlack,
                racesCardBackground: primaryYellow,

                racePromoBackground: primaryBlack,

                button,
                newsEvents,
              }}
            />
          </Route>

          <Route exact path='/events'>
            <Events site='eqt-pittsburgh-10-miler' color={secondaryRed} />
          </Route>

          <Route path='/events/:id'>
            <Event
              site='eqt-pittsburgh-10-miler'
              colors={{
                primary: primaryBlack,
                secondary: primaryYellow,
                button,
              }}
              fallback={moveM}
            />
          </Route>

          {/* TODO: will this be a thing */}
          {/* <Route exact path='/races'>
            <Races
              pageId='races-pittsburgh-marathon'
              colors={{
                primary: yellow,
                secondary: blue,
                tertiary: red,
                button,
              }}
            />
          </Route> */}

          <Route path='/pages/:id'>
            <Page
              site='eqt-pittsburgh-10-miler'
              colors={{
                primary: primaryBlack,
                secondary: primaryYellow,
                tertiary: secondaryRed,
                primaryText: "white",
                linkColor: primaryBlack,
                linkUnderline: primaryYellow,
                linkBlockBackground: primaryYellow,
                accordionBackground: primaryYellow,
                twoColumnBackground: primaryYellow,
                cardsBackground: primaryYellow,
                button,
              }}
              mapboxToken='pk.eyJ1IjoiYnJvd250b3duNDEyIiwiYSI6ImNpamRheHZyejAwNjJ2aG0wa2JyM3U4bW0ifQ.etQxVe143jcNlm9ocMBA6w'
            />
          </Route>

          <Route path='/races/:id'>
            <Race
              site='eqt-pittsburgh-10-miler'
              colors={{
                primary: primaryBlack,
                secondary: primaryYellow,
                tertiary: secondaryRed,
                primaryText: "white",
                accordionBackground: primaryYellow,
                button,
              }}
            />
          </Route>

          <Route exact path='/blog'>
            <News
              site='eqt-pittsburgh-10-miler'
              type='blog'
              newsEventsBackgroundFallback={newsEventsBackgroundFallback}
              colors={{
                primary: primaryBlack,
                secondary: primaryYellow,
                tertiary: secondaryRed,
                primaryText: "white",
                button,
              }}
            />
          </Route>

          <Route exact path='/news'>
            <News
              site='eqt-pittsburgh-10-miler'
              newsEventsBackgroundFallback={newsEventsBackgroundFallback}
              type='news'
              colors={{
                primary: primaryBlack,
                secondary: primaryYellow,
                tertiary: secondaryRed,
                primaryText: "white",
                button,
              }}
            />
          </Route>

          <Route path='/blog/:id'>
            <Post
              site='eqt-pittsburgh-10-miler'
              type='blog'
              newsEventsBackgroundFallback={newsEventsBackgroundFallback}
              colors={{
                primary: primaryBlack,
                secondary: primaryYellow,
                tertiary: secondaryRed,
                button,
              }}
            />
          </Route>

          <Route path='/news/:id'>
            <Post
              site='eqt-pittsburgh-10-miler'
              type='news'
              newsEventsBackgroundFallback={newsEventsBackgroundFallback}
              colors={{
                primary: primaryBlack,
                secondary: primaryYellow,
                tertiary: secondaryRed,
                button,
              }}
            />
          </Route>

          <Route path='/search'>
            <Search
              site='eqt-pittsburgh-10-miler'
              colors={{
                primary: primaryBlack,
                secondary: primaryYellow,
                primaryText: "white",
              }}
            />
          </Route>

          <Route exact path='/KidsVirtual10'>
            <Redirect to='/pages/eqt-kids-virtual-10' />
          </Route>

          <Route path='/404'>
            <Error404 />
          </Route>

          <Route>
            <Redirect to='/404' />
          </Route>
        </Switch>

        <DefaultFooter
          menus={menus}
          logoAlt='EQT Pittsburgh 10 Miler'
          social={{
            facebook: "https://www.facebook.com/runwithp3r",
            twitter: "https://twitter.com/runwithp3r",
            instagram: "https://instagram.com/runwithp3r",
          }}
        />
      </SiteProvider>
    </Router>
  )
}

export default App
